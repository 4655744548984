import React from 'react';
import Form from './Form';
import { validateContactForm as validate } from '../validators';

const fields = [
  {
    name: 'name',
    label: 'Ваше имя',
  },
  {
    name: 'contact',
    label: 'Ваш email или телефон',
  },
  {
    name: 'message',
    label: 'Ваше сообщение',
    textarea: true,
  },
];

const ContactForm = () => (
  <Form
    submitUrl="/feedback"
    fields={fields}
    form="contact"
    validate={validate}
    successMessage={({ reset }) => (
      <div>
        <p className="lead contacts-send-success">
          <i className="fa fa-paper-plane" /> Спасибо за обратную связь!
        </p>
        <p>
          <button className="btn btn-nice" onClick={reset}>
            Связаться еще раз?
          </button>
        </p>
      </div>
    )}
  />
);

export default ContactForm;
