import React from 'react';
import Helmet from 'react-helmet';
import Image from '../components/Image';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import KespaEmail from '../components/KespaEmail';
import KespaPhone from '../components/KespaPhone';
import KespaSkype from '../components/KespaSkype';
import skipSSR from '../components/skipSSR';
import LegalInfo from '../components/LegalInfo';
import ContactForm from '../components/ContactForm';

import '../styles/contacts.scss';

const WrappedContactForm = skipSSR(ContactForm);

const StrahovCard = () => (
  <div className="strahov-card-person">
    <div className="strahov-card-circle-image">
      <Image
        src="teachers/strahov.jpg"
        alt="Алексей Страхов"
        className="teacher-card-circle-image__img"
      />
    </div>
    <div className="strahov-card-person__name">Алексей Страхов</div>
    <div className="strahov-card-person__about">Заведующий учебной частью</div>
  </div>
);

const Contacts = () => (
  <div className="landing">
    <Helmet title="КЭСПА – Контакты" />
    <div className="contacts-wrapper page-wrapper">
      <div className="row">
        <div className="col-sm-5">
          <h2 className="padded-title contact-title">Напишите нам</h2>
          <div className="textarea-block">
            <WrappedContactForm />
          </div>
        </div>
        <div className="col-sm-5 col-sm-offset-1">
          <h2 className="padded-title contact-title">Контакты</h2>
          <StrahovCard />
          <KespaPhone />
          <KespaEmail />
          <KespaSkype />
        </div>
      </div>
      <LegalInfo />

      {/* <div className="legal-info">
        <p className="legal-info__title">Информация об оплате</p>
        <p>
          Оплата становится доступна после регистрации, на странице «Оплата» в
          учебной платформе «Классрум». Оплата происходит через ПАО СБЕРБАНК с
          использованием Банковских карт следующих платежных систем: МИР, VISA
          International, Mastercard Worldwide. В случае мотивированного отказа,
          денежные средства могут быть возвращены в течение 30 дней.
          «Онлайн-школа КЭСПА» вправе удержать 25% от суммы возврата.
        </p>
        <p>
          Для оплаты (ввода реквизитов Вашей карты) вы будете перенаправлены на
          платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача
          информации осуществляется в защищенном режиме с использованием
          протокола шифрования SSL. В случае если Ваш банк поддерживает
          технологию безопасного проведения интернет-платежей Verified By Visa
          или MasterCard SecureCode для проведения платежа также может
          потребоваться ввод специального пароля. Настоящий сайт поддерживает
          256-битное шифрование. Конфиденциальность сообщаемой персональной
          информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет
          предоставлена третьим лицам за исключением случаев, предусмотренных
          законодательством РФ. Проведение платежей по банковским картам
          осуществляется в строгом соответствии с требованиями платежных систем
          МИР, Visa Int. и MasterCard Europe Sprl.
        </p>
      </div> */}
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(Contacts));
