module.exports.validateContactForm = (values = {}) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Укажите, пожалуйста, как мы можем к вам обращаться.';
  }

  if (!values.contact) {
    errors.contact = 'Почта или телефон нужны нам для обратной связи с вами.';
  }

  if (!values.message) {
    errors.message = 'Вы же хотите нам что-то написать, верно?';
  }

  return errors;
};

module.exports.validateSignup = (values = {}) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Обязательное поле';
  }

  if (!values.lastName) {
    errors.lastName = 'Обязательное поле';
  }

  if (!values.password) {
    errors.password = 'Обязательное поле';
  }

  if (!values.email) {
    errors.email = 'Обязательное поле';
  }

  return errors;
};
